import { useTheme } from "../../theme/Theme";

export const RefreshTwoArrowIcon = ({ size = 30, color }: Application.IconProps) => {
  const theme = useTheme();
  const iconColor = color || theme.palette.const.main;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 30 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0083 3.73047C12.4408 3.73047 9.98831 4.59172 8.01581 6.15297C7.47456 6.58047 7.39205 7.36921 7.8208 7.91046C8.24955 8.45171 9.03706 8.53422 9.57831 8.10547C11.1121 6.89172 13.0096 6.23047 15.0083 6.23047C19.8408 6.23047 23.7583 10.148 23.7583 14.9805H22.4202C21.9415 14.9805 21.6682 15.527 21.9555 15.91L24.5435 19.3608C24.7759 19.6706 25.2407 19.6706 25.4731 19.3608L28.0611 15.91C28.3484 15.527 28.0751 14.9805 27.5964 14.9805H26.2583C26.2583 8.76672 21.2221 3.73047 15.0083 3.73047ZM5.47308 10.6002C5.24069 10.2903 4.77591 10.2903 4.54352 10.6002L1.95547 14.0509C1.66822 14.4339 1.9415 14.9805 2.42025 14.9805H3.7583C3.7583 21.1942 8.79455 26.2305 15.0083 26.2305C17.5771 26.2305 20.0283 25.3705 22.0008 23.808C22.542 23.3805 22.6246 22.5917 22.1958 22.0505C21.7671 21.5092 20.9795 21.4267 20.4383 21.8555C18.9033 23.0705 17.0083 23.7305 15.0083 23.7305C10.1758 23.7305 6.2583 19.813 6.2583 14.9805H7.59636C8.0751 14.9805 8.34838 14.4339 8.06113 14.0509L5.47308 10.6002Z"
        fill={iconColor}
      />
    </svg>
  );
};
