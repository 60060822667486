import { Tooltip as MuiTooltip } from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "../../theme/Theme";

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip enterTouchDelay={0} arrow={true} {...props} classes={{ popper: className }} />
))(({ theme }) => {
  const tooltipShadow =
    theme.palette.mode === "dark"
      ? "0px 4px 10px rgba(228, 233, 255, 0.15)"
      : theme.customShadows.menu;

  const tooltipArrowShadow =
    theme.palette.mode === "dark"
      ? "-2px -2px 10px 1px rgba(228, 233, 255, 0.15) !important"
      : "-9px -4px 10px 1px rgba(74, 81, 112, 0.05) !important";

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.walter.main,
      color: theme.palette.gary.main,
      boxShadow: tooltipShadow,
      padding: theme.typography.pxToRem(10),
      borderRadius: theme.typography.pxToRem(8),
      ...theme.typography.text4,
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        backgroundColor: theme.palette.walter.main,
      },
      content: '""',
      backgroundColor: theme.palette.walter.main,
      position: "absolute",
      borderRadius: "2px",
      width: "12px !important",
      height: "12px !important",
      transform: "rotate(-45deg) skew(10deg, 10deg) !important",
      top: "auto !important",
      bottom: "auto !important",
    },
    [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
      [`& .${tooltipClasses.arrow}`]: {
        right: "4px !important",
      },
    },
    [`& .${tooltipClasses.tooltipPlacementRight}`]: {
      [`& .${tooltipClasses.arrow}`]: {
        left: "4px !important",
        boxShadow: tooltipArrowShadow,
      },
    },
  };
});
