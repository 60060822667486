import { persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";

import { authApi } from "../api/authApi";
import { rootReducer } from "./rootReducer";
import { briefcaseApi } from "../api/briefcaseApi";
import { manageFundsApi } from "../api/manageFundsApi";
import { profileApi } from "../api/profileApi";
import { commonApi } from "../api/commonApi";
import { settingsApi } from "../api/settingsApi";
import { strategyApi } from "../api/strategyApi";
import { surveyApi } from "../api/surveyApi";
import { operationsJournalApi } from "../api/operationsJournalApi";
import { requisiteApi } from "../api/requisiteApi";
import { qrCodeApi } from "../api/qrCodeApi";
import { blogApi } from "../api/blogApi";
import { captchaApi } from "../api/captchaApi";
export const apiStoreState = [
  authApi,
  surveyApi,
  commonApi,
  profileApi,
  settingsApi,
  strategyApi,
  blogApi,
  captchaApi,
  briefcaseApi,
  qrCodeApi,
  manageFundsApi,
  operationsJournalApi,
  requisiteApi,
];
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiStoreState.map((api) => api.middleware),
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
