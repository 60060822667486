import { useTheme } from "../../theme/Theme";

export const CloseThenIcon = () => {
  const theme = useTheme();
  const iconColor = theme.palette.gary.main;
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16665_30399)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9917 8.71872C22.5845 8.31153 21.7749 8.46091 21.1835 9.05237L16.1924 14.0435L11.2822 9.13325C10.6908 8.54189 9.88134 8.39259 9.47416 8.79977C9.06697 9.20695 9.21627 10.0164 9.80763 10.6078L14.7178 15.518L9.05143 21.1844C8.45998 21.7759 8.3106 22.5854 8.71778 22.9926C9.12496 23.3998 9.93452 23.2504 10.526 22.659L16.1924 16.9925L21.7481 22.5483C22.3395 23.1396 23.149 23.2889 23.5561 22.8818C23.9633 22.4746 23.814 21.6651 23.2227 21.0737L17.6669 15.518L22.658 10.5269C23.2495 9.93546 23.3988 9.1259 22.9917 8.71872Z"
          fill={iconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_16665_30399">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};
