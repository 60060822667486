import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "../../theme/Theme";
import _debounce from "lodash/debounce";
import { useLazyGetCaptchaQuery, useSendConfirmCaptchaMutation } from "../../api/captchaApi";
import { StatusConfirmCaptcha } from "../../types/app/applicationType";
import { openSnackbarError } from "../../utils/NotificationsUtils";
import {
  changeCaptchaShow,
  changeStatusCaptcha,
  showCaptchaSelector,
  StatusCaptchaEnter,
} from "../../slices/captchaSlice";
import { HeadCaptcha } from "./HeadCaptcha";
import { CaptchaBottom } from "./CaptchaBottom";
import { ActionBody } from "./ActionBody";
import { CaptchaAlert } from "./CaptchaAlert";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "inherit",
    opacity: 0,
    transition: "opacity 0.7s ease-out, transform 0.7s ease-out",
    zIndex: 1500,
    position: "fixed",
    transform: "translate(-50%, -50%) scaleY(0)",
    backgroundColor: "transparent",
    top: "50%",
    left: "50%",
    border: "none",
    width: 335,
    height: 424,
    margin: 0,
    "&[open]": {
      opacity: 1,
      transform: "translate(-50%, -50%) scaleY(1)",
    },
    "&::backdrop": {
      backgroundColor: "rgba(0, 0, 0, 0)",
      transition: "background-color 0.7s",
    },
    "&[open]::backdrop": {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  },
  wrapper: {
    width: 335,
    height: 424,
    backgroundColor: theme.palette.wall.main,
    padding: 20,
    borderRadius: 16,
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    position: "absolute",
  },
  indicatorStatus: {
    position: "absolute",
    height: 45,
    borderRadius: 16,
    top: -5,
    left: 1,
    width: "calc(100% - 3px)",
  },
}));

export const CaptchaWrapper = () => {
  const styles = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const dialogRef = useRef<any>(null);
  const isShowCaptcha = useSelector(showCaptchaSelector);
  const [getCaptcha, { data: getCaptchaData, isFetching: isLoadGetCaptcha }] =
    useLazyGetCaptchaQuery();
  const [sendConfirmCaptcha, { data: dataConfirmCaptcha }] = useSendConfirmCaptchaMutation();

  const [selects, setSelects] = useState<Captcha.CaptchaSelectItem[]>([]);
  const [statusConfirm, setStatusConfirm] = useState<StatusConfirmCaptcha>(
    StatusConfirmCaptcha.CONTINUE,
  );

  const captcha = getCaptchaData?.data;
  const confirmData = dataConfirmCaptcha?.data;
  const isSuccessConfirmCaptcha = confirmData?.status === StatusConfirmCaptcha.VERIFIED;

  const colorIndicator = useMemo(() => {
    switch (statusConfirm) {
      case StatusConfirmCaptcha.FAILED:
        return theme.palette.sparrow.main;
      case StatusConfirmCaptcha.VERIFIED:
        return theme.palette.eye.main;
      case StatusConfirmCaptcha.CONTINUE:
        return theme.palette.bloom.main;
      default:
        return theme.palette.bloom.main;
    }
  }, [statusConfirm]);

  const clearSelects = () => {
    setSelects([]);
  };

  const refreshCaptcha = useCallback(async () => {
    clearSelects();
    try {
      return await getCaptcha({ idCaptcha: captcha?.idCaptcha }).unwrap();
    } catch (e) {
      return openSnackbarError(e);
    }
  }, [captcha?.idCaptcha]);

  const selectFigure = useCallback(
    (item: Captcha.CaptchaSelectItem) => {
      if (!captcha?.idCaptcha) return;
      sendConfirmCaptcha({ idCaptcha: captcha?.idCaptcha, y: item.y, x: item.x })
        .unwrap()
        .then((data) => {
          setStatusConfirm(data?.data?.status);
          if (data?.data?.status === StatusConfirmCaptcha.FAILED) {
            clearSelects();
            refreshCaptcha();
          }
          if (data?.data?.status === StatusConfirmCaptcha.REFRESH) {
            clearSelects();
            refreshCaptcha();
          }
          if (data?.data?.status === StatusConfirmCaptcha.VERIFIED) {
            dispatch(changeStatusCaptcha(StatusCaptchaEnter.SUCCESS));
            clearSelects();
          }
        })
        .catch(openSnackbarError);
      setSelects((prev) => [...prev, item]);
    },
    [captcha?.idCaptcha],
  );

  const closeCaptcha = () => {
    dispatch(changeStatusCaptcha(StatusCaptchaEnter.CANCEL));
    dispatch(changeCaptchaShow(false));
  };

  const successCloseCaptcha = () => {
    dispatch(changeStatusCaptcha(StatusCaptchaEnter.PENDING));
    dispatch(changeCaptchaShow(false));
  };

  const setStateContinue = () => {
    setStatusConfirm(StatusConfirmCaptcha.CONTINUE);
  };

  const debounceFnFailed = useCallback(_debounce(setStateContinue, 2000), []);
  const debounceFnSuccess = useCallback(_debounce(successCloseCaptcha, 2000), []);

  useEffect(() => {
    if (isShowCaptcha) refreshCaptcha();
  }, [isShowCaptcha]);

  useEffect(() => {
    if (confirmData?.status === StatusConfirmCaptcha.FAILED) debounceFnFailed();
    if (confirmData?.status === StatusConfirmCaptcha.VERIFIED) debounceFnSuccess();
  }, [confirmData?.status]);

  useEffect(() => {
    if (dialogRef.current) {
      if (isShowCaptcha) {
        dialogRef.current.showModal();
      } else {
        dialogRef.current.close();
      }
    }
  }, [isShowCaptcha]);

  return (
    <dialog ref={dialogRef} className={styles.root}>
      <div style={{ backgroundColor: colorIndicator }} className={styles.indicatorStatus} />
      {isSuccessConfirmCaptcha ? (
        <CaptchaAlert styles={styles.wrapper} />
      ) : (
        <div className={styles.wrapper}>
          <HeadCaptcha onPress={closeCaptcha} />
          <ActionBody
            isLoad={isLoadGetCaptcha}
            image={captcha?.image}
            status={statusConfirm}
            selects={selects}
            selectFigure={selectFigure}
          />
          <CaptchaBottom onRefresh={refreshCaptcha} textHint={captcha?.hint} />
        </div>
      )}
    </dialog>
  );
};
