import { makeStyles } from "../../theme/Theme";
import classNames from "classnames";
import { Typography } from "@mui/material";
import { TickSuccessIcon } from "../icons/TickSuccessIcon";
import { Spacer } from "../ui/Spacer";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  text: {
    color: theme.palette.eye.main,
  },
}));

interface Props {
  styles?: string;
}

export const CaptchaAlert = ({ styles }: Props) => {
  const style = useStyles();
  return (
    <div className={classNames(styles, style.root)}>
      <TickSuccessIcon />
      <Spacer size={8} />
      <Typography variant={"text3"} className={style.text}>
        Успешная верификация
      </Typography>
    </div>
  );
};
