import React, { useMemo } from "react";
import { makeStyles } from "../../theme/Theme";

import { CaptchaSelect } from "./CaptchaSelect";
import { BottomTextStatus, BottomTextStatusProps } from "./BottomTextStatus";
import { PlaceholderBox } from "../placeholder/PlaceholderBox";
import { StatusConfirmCaptcha } from "../../types/app/applicationType";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 8,
    height: 300,
    width: "100%",
    backgroundColor: "gray",
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
  },
}));

interface Props extends BottomTextStatusProps {
  selectFigure: (item: Captcha.CaptchaSelectItem) => void;
  selects: Captcha.CaptchaSelectItem[];
  image?: Captcha.CaptchaGetResponse["image"];

  isLoad: boolean;
}

export const ActionBody = ({ selectFigure, selects, image, status, isLoad }: Props) => {
  const styles = useStyles();

  const onSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isLoad) return;
    const clickX = event.clientX;
    const clickY = event.clientY;

    // @ts-ignore
    const blockRect = event.target.getBoundingClientRect();

    // Вычисляем координаты клика относительно блока
    const relativeX = clickX - blockRect.left - 16;
    const relativeY = clickY - blockRect.top - 19;
    selectFigure({
      id: Date.now(),
      y: parseInt(relativeY + ""),
      x: parseInt(relativeX + ""),
    });
  };

  const isShowBottomStatus = useMemo(() => {
    if (status === StatusConfirmCaptcha.FAILED) {
      return true;
    }
    if (status === StatusConfirmCaptcha.VERIFIED) {
      return true;
    }
    return false;
  }, [status]);

  return (
    <div onClick={onSelect} className={styles.root}>
      {selects.map((item, index) => (
        <CaptchaSelect num={index + 1} key={item.id} x={item.x} y={item.y} />
      ))}
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      {isLoad ? (
        <PlaceholderBox width={295} height={300} />
      ) : (
        <img style={{ width: 295, height: 300 }} src={image} />
      )}
      {isShowBottomStatus && <BottomTextStatus status={status} />}
    </div>
  );
};
