import { useTheme } from "../../theme/Theme";

export const TickSuccessIcon = () => {
  const theme = useTheme();
  const iconColor = theme.palette.eye.main;
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66667 11.6667L7.36091 13.6873C7.71815 13.9553 8.2231 13.894 8.50587 13.5484L15.5 5"
        stroke={iconColor}
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
    </svg>
  );
};
