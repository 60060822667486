import React from "react";
import { CaptchaWrapper } from "../components/captcha/CaptchaWrapper";

interface Props {
  children: React.ReactNode;
}
export const CaptchaContainer = ({ children }: Props) => {
  return (
    <>
      <CaptchaWrapper />

      {children}
    </>
  );
};
