import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchQuery } from "../helpers/ApiHelpers";
import { ApiRoutes } from "../constants/ApiRoutes";
import { transformBlogResponseList } from "../utils/BlogUtils";

export const blogApi = createApi({
  reducerPath: "blogApi",
  baseQuery: fetchQuery(),
  endpoints: (build) => ({
    getList: build.query<Application.ResponseProps<Blog.BlogWithText[] | Blog.Blog[]>, void>({
      query: () => ApiRoutes.GetBlogList,
      transformResponse: transformBlogResponseList,
    }),
  }),
});

export const { useGetListQuery } = blogApi;
