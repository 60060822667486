const cleanHtml = (html: string) => {
  return html
    .replace(/<img[^>]*>/g, "") // Убираем незакрытые <img> теги, если они есть
    .replace(/&nbsp;/g, " ") // Заменяем &nbsp; на пробел
    .replace(/&/g, "&amp;") // Заменяем & на &amp; для корректной обработки
    .replace(/<br>/g, "") // Убираем теги <br>, которые могут вызывать проблемы
    .replace(/<meta[^>]+>/g, "") // Убираем незакрытые <meta> теги
    .replace(/<link[^>]+>/g, "") // Убираем незакрытые <link> теги
    .replace(/[=]""/g, '=""') // Закрываем атрибуты без значения
    .replace(/<[^>]+$/, "") // Убираем незакрытые теги в конце
    .trim();
};

const cleanText = (text: string) => {
  return text
    .replace(/\n/g, " ") // Заменяем все новые строки на пробелы
    .replace(/\s\s+/g, " ") // Убираем повторяющиеся пробелы
    .trim(); // Убираем пробелы в начале и в конце текста
};

const replaceHtmlEntities = (html: string) => {
  return html
    .replace(/&nbsp;/g, " ") // Замена неразрывного пробела на обычный пробел
    .replace(/&amp;/g, "&") // Замена &amp; на &
    .replace(/&quot;/g, '"') // Замена &quot; на "
    .replace(/&lt;/g, "<") // Замена &lt; на <
    .replace(/&gt;/g, ">"); // Замена &gt; на >
};
const getHtmlPageToText = async (url: string) => {
  const response = await fetch(url);
  return await response.text();
};

const getHtmlWithAbsoluteUrl = (html: string) => {
  const baseUrl = "https://www.fbaugust.ru/";

  const doc = new DOMParser().parseFromString(html, "text/html");

  const makeAbsoluteUrl = (url: string) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }
    return `${baseUrl}${url.startsWith("/") ? "" : "/"}${url}`;
  };

  const links = doc.getElementsByTagName("link");
  for (let i = 0; i < links.length; i++) {
    const href = links[i].getAttribute("href");
    if (href) {
      links[i].setAttribute("href", makeAbsoluteUrl(href));
    }
  }

  const linksA = doc.getElementsByTagName("a");
  for (let i = 0; i < linksA.length; i++) {
    const href = linksA[i].getAttribute("href");
    if (href) {
      linksA[i].setAttribute("href", makeAbsoluteUrl(href));
    }
  }

  const script = doc.getElementsByTagName("script");
  for (let i = 0; i < script.length; i++) {
    const href = script[i].getAttribute("src");
    if (href) {
      script[i].setAttribute("src", makeAbsoluteUrl(href));
    }
  }

  const images = doc.getElementsByTagName("img");
  for (let i = 0; i < images.length; i++) {
    const src = images[i].getAttribute("src");
    if (src) {
      images[i].setAttribute("src", makeAbsoluteUrl(src));
    }
  }

  return doc.toString();
};
export const getDataContentFromBlog = async (
  _html: string,
  xPathContent: string,
): Promise<string> => {
  const html = cleanHtml(cleanText(replaceHtmlEntities(_html)));

  if (!xPathContent) return html;
  return "";
};
const blogToWortFormat = (blog: Blog.BlogResponseBase): Blog.Blog[] => {
  return blog.items.map((item, index) => {
    return {
      ...item,
      dateCreate: item.dateCreate + " 00:00:00",
      excludeEls: blog.excludeEls,
      includeContent: blog.includeContent,
    };
  });
};
export const getBlogWithText = async (blog: Blog.Blog) => {
  const html = await getHtmlPageToText(blog.linkSource);
  const newContentText = await getDataContentFromBlog(html, blog.includeContent);
  const newData: Blog.BlogWithText = {
    ...blog,
    textContent: newContentText.trimStart(),
    pageText: getHtmlWithAbsoluteUrl(html),
  };
  return newData;
};
export const transformBlogResponseList = async (
  response: Application.ResponseProps<Blog.BlogResponseBase>,
): Promise<
  Application.ResponseProps<Blog.BlogWithText[]> | Application.ResponseProps<Blog.Blog[]>
> => {
  if (!response.data)
    return {
      data: undefined,
      code: 1,
      text: "",
    };
  const blogs = blogToWortFormat(response.data);

  return {
    ...response,
    data: blogs,
  };
};

export const removeElementsByXPathArray = (xPathExclude: string[]) => {
  return `
<script type="text/javascript">
function removeElementsByXPathArray(xpathArray) {
  xpathArray.forEach((xpath) => {
    
    const elements = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
      null,
    );

        if (elements.snapshotLength > 0) {
          for (let i = 0; i < elements.snapshotLength; i++) {
            const element = elements.snapshotItem(i);
    
            // Удаляем найденный элемент
            if (element) {
              element.parentNode.removeChild(element);
            }
          }
        }
      });
    }
    let interval = null;
    const xpathsToRemove = [${xPathExclude.map((item) => '"' + item + '"')}];
    interval = setInterval(() => {
      removeElementsByXPathArray(xpathsToRemove);
    }, 1000);
    setTimeout(() => {
    clearInterval(interval);
    }, 7000)
    </script>
  `;
};
