import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/configureStore";
export enum StatusCaptchaEnter {
  CANCEL,
  SUCCESS,
  PENDING,
  UNKNOWN,
}
interface ICaptcha {
  showCaptcha: boolean;
  statusEnterCaptcha: StatusCaptchaEnter;
}
interface ICaptcha {
  showCaptcha: boolean;
  statusEnterCaptcha: StatusCaptchaEnter;
  callback?: (value: StatusCaptchaEnter) => void; // Колбэк для завершения
}

const initialState: ICaptcha = {
  showCaptcha: false,
  statusEnterCaptcha: StatusCaptchaEnter.UNKNOWN,
};

export const captchaSlice = createSlice({
  initialState,
  name: "captchaSlice",
  reducers: {
    changeCaptchaShow: (state, action: PayloadAction<boolean>) => {
      state.showCaptcha = action.payload;
      if (!action.payload) {
        state.statusEnterCaptcha = StatusCaptchaEnter.UNKNOWN;
        state.callback = undefined;
      }
    },
    changeStatusCaptcha: (state, action: PayloadAction<StatusCaptchaEnter>) => {
      state.statusEnterCaptcha = action.payload;
      if (state.callback) {
        state.callback(action.payload);
        state.callback = undefined;
        state.statusEnterCaptcha = StatusCaptchaEnter.PENDING;
      }
    },
    setCaptchaCallback: (state, action: PayloadAction<(value: StatusCaptchaEnter) => void>) => {
      state.callback = action.payload;
    },
  },
});

export const { changeCaptchaShow, changeStatusCaptcha, setCaptchaCallback } = captchaSlice.actions;

export const showCaptchaSelector = (state: RootState) => state.captchaSlice.showCaptcha;
export const statusCaptchaSelector = (state: RootState) => state.captchaSlice.statusEnterCaptcha;
