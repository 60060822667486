import { cloneDeep, isArray } from "lodash";
import { getFileDataInBase64 } from "../utils/SystemUtils";
import { surveyApi } from "../api/surveyApi";
import { store } from "../store/configureStore";
import { formatDate } from "./DateHelpers";

type HashTableBeforeAnswer = Record<Survey.AnswerPrev["id"], Survey.AnswerPrev>;
export const makeTitleStep = (answerValueFields: Survey.FormProps, index: number) => {
  const surveyType = answerValueFields.steps[index]?.surveyType || "";
  const uniqueArraySurveyTypes = Array.from(new Set(answerValueFields.type)) as string[];
  const indexNameStep = uniqueArraySurveyTypes.indexOf(surveyType);
  const title = answerValueFields.text?.length ? answerValueFields.text[indexNameStep] : "";
  return title || "";
};
export const createAnswerForSend = async (
  scenario: Survey.ScenarioType,
): Promise<Pick<Survey.SendSurveyItemsQueryProps, "stepId" | "fields">> => {
  const querySurveyData = await store
    .dispatch(surveyApi.endpoints.getSurveyData.initiate({ scenario }))
    .unwrap();
  const querySurveyAnswer = await store
    .dispatch(surveyApi.endpoints.getSurveyAnswers.initiate({ scenario }))
    .unwrap();

  const surveyData = querySurveyData?.data;
  const surveyAnswer = querySurveyAnswer?.data ?? [];

  const answerToObj: Survey.AnswerObjIdKey = surveyAnswer.reduce((acc, answer) => {
    acc[answer.id] = answer;
    return acc;
  }, {} as Survey.AnswerObjIdKey);
  const fields: Array<Survey.StepFieldProps> | undefined = surveyData?.steps.flatMap((item) => {
    return item.fields.map((field) => ({
      ...field,
      stepDescription: item.description,
      surveyType: item.surveyType,
    }));
  });
  // @ts-ignore
  return {
    // @ts-ignore
    stepId: surveyData?.id,
    // @ts-ignore
    fields: fields
      .map((item) => {
        const objValue: Pick<Survey.SaveField, "value" | "text" | "documentsBase64"> = {
          value: "",
          text: "",
          documentsBase64: [],
        };
        switch (item.type) {
          case "checkbox":
          case "date":
          case "text":
          case "list":
          case "radio":
            objValue.value = answerToObj[item.id]?.answer;
            objValue.text = item.listItems?.find(
              (variant) => variant.value === answerToObj[item.id]?.answer,
            )?.text;
            break;
          case "document":
            // @ts-ignore
            objValue.value = answerToObj[item.id]?.files_answers;
            // @ts-ignore
            objValue.text = answerToObj[item.id]?.files_answers;
            objValue.documentsBase64 = answerToObj[item.id]?.files_answers;
        }
        return {
          id: item.id,
          title: item.title,
          format: item.format,
          surveyType: item.surveyType,
          stepDescription: item.stepDescription,
          listItems: item.listItems,
          ...objValue,
        };
      })
      .filter((_) => _.id),
  };
};

export const transformAnswerSurveyToPreviewPersonalData = async (
  scenarios: Survey.ScenarioType[],
): Promise<Profile.SectionPreviewPersonalData[]> => {
  const sections = Promise.all(
    scenarios.map(async (scenario) => {
      const querySurveyData = await store
        .dispatch(surveyApi.endpoints.getSurveyData.initiate({ scenario }))
        .unwrap();
      const querySurveyAnswer = await store
        .dispatch(surveyApi.endpoints.getSurveyAnswers.initiate({ scenario }))
        .unwrap();

      const surveyData = querySurveyData?.data;
      const surveyAnswer = querySurveyAnswer?.data ?? [];

      const typesRu = surveyData?.text ?? [];
      const types = surveyData?.type ?? [];
      const typesUniq = Array.from(new Set(types));

      const dictTypesWithRu: Partial<Record<Survey.SurveyType, string>> = {};
      for (const keyTypeUniq in typesUniq) {
        dictTypesWithRu[typesUniq[keyTypeUniq]] = typesRu[keyTypeUniq];
      }

      const answerToObj: Survey.AnswerObjIdKey = surveyAnswer.reduce((acc, answer) => {
        acc[answer.id] = answer;
        return acc;
      }, {} as Survey.AnswerObjIdKey);

      const valuesByType: Partial<Record<Survey.SurveyType, Survey.StepFieldProps[]>> | undefined =
        surveyData?.steps.reduce((acc, step) => {
          const typeStep = step.surveyType;
          if (acc[typeStep]) {
            acc[typeStep] = [...acc[typeStep], ...step.fields];
          } else {
            acc[typeStep] = step.fields;
          }
          return acc;
        }, {});

      const questionsFillAnswer:
        | Partial<Record<Survey.SurveyType, Profile.SectionPreviewPersonalData>>
        | undefined = surveyData?.steps.reduce((accumulator, step) => {
        const acc = accumulator as Record<Survey.SurveyType, Profile.SectionPreviewPersonalData>;
        let accName = acc[step.surveyType];

        if (!accName) {
          accName = {} as Profile.SectionPreviewPersonalData;
        }
        if (accName && !accName?.titleSection) {
          accName.titleSection = dictTypesWithRu[step.surveyType] ?? "";
        }
        if (accName && !accName?.subTitleSection) {
          accName.subTitleSection = step.description ?? "";
        }

        if (valuesByType) {
          accName.values =
            valuesByType[step.surveyType]?.map((item) => {
              const baseObj: Partial<
                | Profile.SectionPreviewPersonalDataValueText
                | Profile.SectionPreviewPersonalDataValueFile
              > = {
                id: item.id,
                title: item.title,
              };

              switch (item.type) {
                case "text":
                  baseObj.type = "text";
                  break;
                case "date":
                  baseObj.type = "date";
                  break;
                case "document":
                  baseObj.type = "file";
                  break;
                default:
                  baseObj.type = "text";
              }

              switch (baseObj.type) {
                case "text":
                  if (item.type === "text") {
                    baseObj.value = answerToObj[item.id]?.answer ?? "";
                    break;
                  }
                  if (item.type === "list") {
                    baseObj.value =
                      item?.listItems?.find(
                        (listItem) => listItem?.value === answerToObj[item.id]?.answer,
                      )?.text ?? "";
                    break;
                  }
                  if (item.type === "radio") {
                    baseObj.value =
                      item?.listItems?.find(
                        (listItem) => listItem?.value === answerToObj[item.id]?.answer,
                      )?.text ?? "";
                    break;
                  }
                  if (item.type === "checkbox") {
                    baseObj.value =
                      item?.listItems?.find(
                        (listItem) => listItem?.value === answerToObj[item.id]?.answer,
                      )?.text ?? "";
                    break;
                  }
                  baseObj.value = answerToObj[item.id]?.answer ?? "";
                  break;
                case "date":
                  const answer = answerToObj[item.id]?.answer ?? "";
                  const date = answer.includes("T")
                    ? formatDate(answerToObj[item.id]?.answer)
                    : answer;
                  baseObj.value = date;
                  break;
                case "file":
                  baseObj.files = answerToObj[item.id]?.files_answers ?? [];
                  break;
              }

              return baseObj as
                | Profile.SectionPreviewPersonalDataValueText
                | Profile.SectionPreviewPersonalDataValueFile;
            }) ?? [];
        }

        acc[step.surveyType] = accName;
        return acc;
      }, {});

      return Object.values(questionsFillAnswer ?? {}) ?? [];
    }),
  );
  return sections.then((item) => item.flatMap((section) => section));
};

interface GenerateValueForFieldValueType {
  defaultValues: Partial<Record<Survey.StepFieldFormatType, string>> | undefined;
  field: Survey.StepFieldProps;
  hashTableBeforeAnswer?: HashTableBeforeAnswer | undefined;
}

const generateValueForFieldByType = (
  value: string | string[] | undefined,
  field: Survey.StepFieldProps,
) => {
  if (field.type === "list") {
    return field.listItems.find((item) => item.value === value)?.value;
  }
  if (field.type === "document" && isArray(value)) {
    return value.map((item) => ({ url: item }));
  }
  return value;
};

const generateValueForField = ({
  defaultValues,
  field,
  hashTableBeforeAnswer,
}: GenerateValueForFieldValueType): string | string[] | undefined => {
  if (defaultValues) {
    if (defaultValues[field.format]) {
      return defaultValues[field.format];
    }
  }
  const idField = field.id;
  if (field.type === "document") {
    if (hashTableBeforeAnswer?.[idField]?.files_answers?.length) {
      return hashTableBeforeAnswer?.[idField]?.files_answers;
    } else {
      return undefined;
    }
  }
  if (field.type === "checkbox") {
    if (hashTableBeforeAnswer?.[idField]?.answer.length) {
      return hashTableBeforeAnswer?.[idField]?.answer.split(",").map((item) => item.trim());
    } else {
      return undefined;
    }
  }

  return hashTableBeforeAnswer?.[idField]?.answer;
};

export function formatSurveyItems(
  data: Survey.FormProps,
  beforeAnswer?: Survey.AnswerPrev[],
): Survey.FormProps {
  if (!data) {
    return DEFAULT_VALUES_SURVEY_FORM_PROPS;
  }

  const hashTableBeforeAnswer = isArray(beforeAnswer)
    ? beforeAnswer?.reduce<HashTableBeforeAnswer>((acc, item) => {
        const newObg: HashTableBeforeAnswer = { ...acc, [item.id]: item };
        return newObg;
      }, {})
    : undefined;
  const defaultValues: Partial<Record<Survey.StepFieldFormatType, string>> | undefined =
    data.defaultFieldValues;
  return {
    ...data,
    steps: data.steps.map((step) => {
      const fields = step.fields.map((field) => {
        let editable = field.editable;

        const value = generateValueForFieldByType(
          generateValueForField({
            hashTableBeforeAnswer,
            defaultValues,
            field,
          }),
          field,
        );

        if (!value) {
          editable = true;
        }
        return { ...field, editable, default: value };
      });
      return { ...step, fields };
    }),
  };
}

function getFieldValue(field: Survey.StepFieldProps) {
  switch (field.type) {
    case "document": {
      const files = field.default as File[];
      return files?.map((file) => file.name);
    }
    case "checkbox": {
      const checkedItems = field.listItems?.filter((item) => {
        return field.default?.includes(item.value);
      });
      return checkedItems?.map((item) => item.text).join(", ");
    }
    case "radio":
    case "list":
      return field.default
        ? field.listItems?.find((item) => item.value === field.default)?.value
        : "";
    default:
      return field.default;
  }
}
function getFieldText(field: Survey.StepFieldProps) {
  switch (field.type) {
    case "document": {
      const files = field.default as File[];
      return files?.map((file) => file.name);
    }
    case "checkbox": {
      const checkedItems = field.listItems?.filter((item) => {
        return field.default?.includes(item.value);
      });
      return checkedItems?.map((item) => item.text).join(", ");
    }
    case "radio":
    case "list":
      return field.default
        ? field.listItems?.find((item) => item.value === field.default)?.text
        : "";
    default:
      return field.default;
  }
}

const prepareFields = (
  fields: Survey.StepFieldProps[],
  step: Survey.StepProps,
): Partial<Survey.StepFieldProps>[] => {
  return fields.map((field) => {
    const newField: Partial<Survey.StepFieldProps> = {
      ...field,
      stepDescription: step.description,
      surveyType: step.surveyType,
    };
    return newField;
  });
};

export async function getFieldValuesFormSteps(
  steps: Survey.StepProps[],
): Promise<Survey.SaveField[]> {
  const fields = steps.reduce((acc, step) => {
    return [...acc, ...prepareFields(step.fields, step)];
  }, [] as Partial<Survey.StepFieldProps>[]);

  return new Promise(async (resolve) => {
    const submitStep: Survey.SaveField[] = [];
    for (const field of fields) {
      const newFieldSubmit: Survey.SaveField = {
        id: field.id as string,
        value: getFieldValue(field as Survey.StepFieldProps),
        text: getFieldText(field as Survey.StepFieldProps),
        title: field.title,
        format: field.format,
        surveyType: field.surveyType,
        stepDescription: field.stepDescription,
        listItems: field.listItems?.map((item) => item.text),
      };
      if (field.type === "document" && Array.isArray(field.default)) {
        const files: string[] = [];
        for (const file of field.default) {
          files.push(await getFileDataInBase64(file as File, true));
        }
        newFieldSubmit.documentsBase64 = files;
      }
      submitStep.push(newFieldSubmit);
    }
    resolve(submitStep);
  });
}

export function getUploadData(files: File[]) {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append(`file[]`, files[i]);
  }

  return formData;
}

export function checkSurveyFieldsValid(fields: Survey.StepFieldProps[]) {
  const checkFieldValid = (field: Survey.StepFieldProps) => {
    if (field.isMask) {
      if (field.mask) {
        return field.mask?.length === field.default?.length;
      }
    }
    if (field.editable === false) {
      return true;
    }

    if (field.required && (!field.default || field.default.length === 0)) {
      return false;
    }

    return true;
  };

  return fields?.every(checkFieldValid);
}

export const DEFAULT_VALUES_SURVEY_FORM_PROPS: Survey.FormProps = {
  isRequestAnswers: false,
  scenario: "POD_FT",
  id: 0,
  steps: [],
  text: [],
  type: [],
};
export function prepareSurveyFormData(data?: Survey.FormProps): Survey.FormProps {
  if (!data) {
    return DEFAULT_VALUES_SURVEY_FORM_PROPS;
  }

  const formData = cloneDeep(data);
  const defaultValues = formData.defaultFieldValues;

  formData.steps.forEach((step) => {
    step.fields.forEach((field) => {
      if (defaultValues && field.format && defaultValues[field.format]) {
        field.default = defaultValues[field.format];
      }
    });
  });

  return { ...DEFAULT_VALUES_SURVEY_FORM_PROPS, ...formData };
}

export function checkFieldWidthFixed(field: Survey.StepFieldProps) {
  if (field.type === "date") {
    return true;
  }

  switch (field.format) {
    case "gender":
    case "inn":
    case "snils":
      return true;
    default:
      return false;
  }
}

export function checkShowPhoneOrEmailEditingNotification(fields: Survey.StepFieldProps[]) {
  const phoneField = fields?.find((field) => field.format === "phone");
  const emailField = fields?.find((field) => field.format === "email");

  if (phoneField || emailField) {
    return true;
  }

  return false;
}

export function getPlaceholder(field: Survey.StepFieldProps) {
  if (field.type === "date") {
    return "Выберите дату";
  }

  switch (field.format) {
    case "citezenship":
      return "Укажите страну";
    case "snils":
      return "000-000-000 00";
    default:
      return "";
  }
}
