import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { ApiMethod, fetchQuery } from "../helpers/ApiHelpers";

export const operationsJournalApi = createApi({
  reducerPath: "operationsJournalApi",
  baseQuery: fetchQuery(),
  endpoints: (builder) => ({
    getOperationsWithdrawalList: builder.query<
      Application.ResponsePaginationProps<OperationsJournal.Props[]>,
      Application.RequestPaginationProps<OperationsJournal.OperationStatus[]>
    >({
      query: ({ data, ...args }) =>
        createUrl(ApiRoutes.OperationsJournalWithdrawalItems, {
          query: { status: data, ...args },
        }),
    }),
    getOperationsOtherList: builder.query<
      Application.ResponsePaginationProps<OperationsJournal.OperationsOtherJournal[]>,
      Application.RequestPaginationProps<OperationsJournal.OperationStatus[]>
    >({
      query: ({ data, ...args }) =>
        createUrl(ApiRoutes.OperationsJournalOtherItems, {
          query: { status: data, ...args },
        }),
      transformResponse: (
        response: Application.ResponsePaginationProps<OperationsJournal.OperationsOtherJournal[]>,
      ): Application.ResponsePaginationProps<OperationsJournal.OperationsOtherJournal[]> => {
        const list = response.data?.map((item) => {
          return {
            ...item,
            status: item.status === "cancelled" ? "revoked" : item.status,
          };
        });
        return {
          ...response,
          data: list,
        };
      },
    }),
    getDepositOperationsList: builder.query<
      Application.ResponsePaginationProps<OperationsJournal.Props[]>,
      Application.RequestPaginationProps<void>
    >({
      query: (args) =>
        createUrl(ApiRoutes.OperationsJournalDepositItems, {
          query: args,
        }),
    }),
    getTransferOperationsList: builder.query<
      Application.ResponsePaginationProps<OperationsJournal.Props[]>,
      Application.RequestPaginationProps<OperationsJournal.OperationStatus[]>
    >({
      query: ({ data, ...args }) =>
        createUrl(ApiRoutes.OperationsJournalTransferItems, {
          query: { status: data, ...args },
        }),
    }),
    cancelOperation: builder.mutation<
      Application.ResponseProps,
      OperationsJournal.CancelOperationQueryProps
    >({
      query: ({ id }) => ({
        url: createUrl(ApiRoutes.OperationsJournalWithdrawalCancel, {
          params: { id },
        }),
        method: ApiMethod.Post,
      }),
    }),
    cancelOperationConfirm: builder.mutation<
      Application.ResponseProps,
      OperationsJournal.CancelOperationConfirmQueryProps
    >({
      query: ({ id, code }) => ({
        url: createUrl(ApiRoutes.OperationsJournalWithdrawalCancelConfirm, {
          params: { id },
          query: { code },
        }),
        method: ApiMethod.Post,
      }),
    }),
  }),
});

export const {
  // withdrawal
  useGetOperationsWithdrawalListQuery,
  useLazyGetOperationsWithdrawalListQuery,

  // transfer
  useLazyGetTransferOperationsListQuery,
  useGetTransferOperationsListQuery,

  // replenish
  useGetDepositOperationsListQuery,
  useLazyGetDepositOperationsListQuery,

  // other operation
  useLazyGetOperationsOtherListQuery,
  useGetOperationsOtherListQuery,

  // cancel
  useCancelOperationMutation,
  useCancelOperationConfirmMutation,
} = operationsJournalApi;
