import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { ApiMethod, fetchQuery } from "../helpers/ApiHelpers";
import { HttpMethod } from "../dto/ApplicationDTO";

enum SettingsApiTypes {
  Documents = "Documents",
}

export const documentsApi = createApi({
  reducerPath: "documentsApi",
  baseQuery: fetchQuery(),
  tagTypes: [SettingsApiTypes.Documents],
  endpoints: (builder) => ({
    documents: builder.query<Application.ResponseProps<Documents.Props[]>, void>({
      query: () => ApiRoutes.DocumentsList,
    }),
    documentSign: builder.mutation<
      Application.ResponseProps<Common.ActionIdUpateProps>,
      { id: string }
    >({
      query: (query) => ({
        method: ApiMethod.Get,
        url: createUrl(ApiRoutes.DocumentSign, { params: query }),
      }),
    }),
    documentsSignCheck: builder.mutation<
      Application.ResponseProps<void>,
      Documents.DocumentsSignCheckRequestParams
    >({
      query: (query) => {
        return {
          url: createUrl(ApiRoutes.DocumentSignCheck, { params: { id: query.id }, query }),
          method: HttpMethod.Get,
        };
      },
    }),
    getListReferenceInformation: builder.query<
      Application.ResponseProps<Documents.ReferenceInformation[]>,
      void
    >({
      query: () => ApiRoutes.GetListReferenceInformation,
    }),
    requestReference: builder.mutation<
      Application.ResponseWithActionId,
      Documents.CreateReferenceInformation
    >({
      query: (query) => createUrl(ApiRoutes.RequestReferenceInformation, { query }),
    }),
    confirmCodeReference: builder.mutation<
      Application.ResponseProps<void>,
      Application.QueryWithActionIdAndCode
    >({
      query: (query) => createUrl(ApiRoutes.ConfirmReferenceInformation, { query }),
    }),
  }),
});

export const {
  useConfirmCodeReferenceMutation,
  useRequestReferenceMutation,
  useLazyGetListReferenceInformationQuery,
  useGetListReferenceInformationQuery,
  useDocumentsQuery,
  useDocumentSignMutation,
  useDocumentsSignCheckMutation,
} = documentsApi;
