import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { fetchQuery } from "../helpers/ApiHelpers";
import { createUrl } from "../utils/UrlUtils";
import { ApiRoutes } from "../constants/ApiRoutes";

export const captchaApi = createApi({
  reducerPath: "captchaApi",
  baseQuery: fetchQuery(),
  endpoints: (build) => ({
    getCaptcha: build.query<
      Application.ResponseProps<Captcha.CaptchaGetResponse>,
      Captcha.CaptchaGetRequest
    >({
      query: (query) => createUrl(ApiRoutes.RefreshCaptcha, { query }),
    }),
    sendConfirmCaptcha: build.mutation<
      Application.ResponseProps<Captcha.CaptchaConfirmResponse>,
      Captcha.CaptchaConfirmRequest
    >({
      query: (query) => createUrl(ApiRoutes.SendCaptcha, { query }),
    }),
  }),
});

export const { useLazyGetCaptchaQuery, useSendConfirmCaptchaMutation } = captchaApi;
