import React from "react";
import { makeStyles, useTheme } from "../../theme/Theme";
import { InfoOutlineIcon } from "../icons/InfoOutlineIcon";
import { RefreshTwoArrowIcon } from "../icons/RefreshTwoArrowIcon";
import { Tooltip } from "../ui/Tooltip";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "row",
    marginTop: 16,
    position: "relative",
  },
  iconWrapper: {
    marginRight: 8,
    zIndex: 1,
  },
  button: {
    cursor: "pointer",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    "&>svg,path": {
      "stroke-width": 2,
    },
  },
  tooltip: {
    "&>.MuiTooltip-tooltip": {
      marginBottom: "3px !important",
      transform: "translateX(10px) !important",
    },
    "& span.MuiTooltip-arrow": {
      left: "3px !important",
      bottom: "8px !important",
      transform: "rotate(-45deg) !important",
    },
  },
}));

interface Props {
  onRefresh: VoidFunction;
  textHint?: Captcha.CaptchaGetResponse["hint"];
}

export const CaptchaBottom = ({ onRefresh, textHint = "" }: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <div className={styles.root}>
      <Tooltip className={styles.tooltip} placement={"top-start"} title={textHint}>
        <button className={styles.button}>
          <InfoOutlineIcon size={24} color={theme.palette.total.main} />
        </button>
      </Tooltip>
      <button onClick={onRefresh} className={styles.button}>
        <RefreshTwoArrowIcon color={theme.palette.total.main} size={24} />
      </button>
    </div>
  );
};
