import cx from "classnames";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { makeStyles, useIsMobileDevice, useTheme } from "../../../theme/Theme";
import { BoxProps, Stack, IconButton, Paper, StackProps, Theme } from "@mui/material";
import { CloseIcon } from "../../icons/CloseIcon";
import { isNumber, isString, merge, omit } from "lodash";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Backdrop } from "../../sidebar/Backdrop";
import { formatHexToRGBA } from "../../../utils/FormatUtils";
import { OptionValue, SelectOption } from "./SelectOption";
import { SelectHeader } from "./SelectHeader";
import { useClickAway } from "../../../hooks/useClickAway";
import { Box } from "../Box";
import { SxProps } from "@mui/system";

interface StylesProps {
  disabled?: boolean;
  maxPopoverHeight?: number;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: {},
  popoverContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: theme.palette.walter.main,
    maxHeight: (props) =>
      props.maxPopoverHeight
        ? theme.typography.pxToRem(props.maxPopoverHeight)
        : theme.typography.pxToRem(230),
    color: theme.palette.gary.main,
    borderRadius: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(16),
  },
  input: {
    opacity: (props) => (props.disabled ? 0.5 : 1.0),
    background: theme.palette.walter.main,
    boxShadow: theme.shadows[5],
    borderRadius: theme.typography.pxToRem(16),
    color: theme.palette.gary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid transparent",
    paddingTop: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    cursor: (props) => (props.disabled ? "default" : "pointer"),

    transition: "box-shadow 200ms ease-in-out",
    "&:hover": {
      boxShadow: (props) => (props.disabled ? theme.shadows[5] : theme.customShadows.input),
    },
  },
  backdropOption: {
    color: theme.palette.total.main,
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
    paddingTop: 10,
    borderBottom: `1px solid ${formatHexToRGBA({ color: theme.palette.gary.main, alpha: 0.2 })}`,
    cursor: "pointer",
    textDecoration: "none",

    "&:last-child": {
      paddingBottom: 0,
      borderBottom: "none",
    },
  },
  chevronAnimated: {
    transition: "transform 200ms ease-in-out",
  },
}));

export interface SelectProps extends Omit<BoxProps, "onSelect"> {
  label?: ReactElement | null | undefined;
  popoverLabel?: ReactElement | null;
  placeholder?: string;
  onSelect?: (value: OptionValue) => void;
  onClick?: () => void;
  disablePopover?: boolean;
  disabled?: boolean;
  bottomGutterHeight?: number;
  maxPopoverHeight?: number;
  optionStackProps?: StackProps;
  bottomSheerHeader?: string;
  sxPopper?: SxProps<Theme>;
}

export function Select({
  label,
  popoverLabel,
  children,
  placeholder = "Выберите вариант",
  onSelect,
  onClick,
  maxPopoverHeight,
  optionStackProps,
  sxPopper,
  disabled = false,
  disablePopover = false,
  bottomGutterHeight = 20,
  bottomSheerHeader,
  ...props
}: PropsWithChildren<SelectProps>) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [backdropOpened, setBackdropOpened] = useState(false);
  const [open, setOpen] = React.useState(false);

  const popperContentRef = useRef(null);

  const classes = useStyles({ disabled, maxPopoverHeight });
  const isMobileDevice = useIsMobileDevice();
  const selectInputRef = useRef<HTMLElement>(null);
  const theme = useTheme();

  const size = useWindowSize();
  const [inputProperties, setInputProperties] = useState<{ width: number } | null>(null);

  const FilteredOptions = useMemo(() => {
    if (!children) return children;
    const newChildren: ReactElement[] = [];

    React.Children.forEach(children, (child, index) => {
      if (
        React.isValidElement(child) &&
        (isNumber(child.props.value) || isString(child.props.value))
      ) {
        if (isMobileDevice) {
          newChildren.push(
            <Box
              onClick={() => {
                onSelect?.(child.props.value);
              }}
              paddingTop={index === 0 ? 0 : undefined}
              key={index}
              clickable={true}
              disabled={disabled}
              className={cx(classes.backdropOption)}
            >
              <SelectOption {...child.props} key={`select-option-${index}`} />
            </Box>,
          );
        } else {
          newChildren.push(
            <SelectOption
              {...child.props}
              key={`select-option-${index}`}
              onClick={() => {
                onSelect?.(child.props.value);
                handleClose();
              }}
            />,
          );
        }
        return;
      }

      // eslint-disable-next-line no-console
      console.error("You provided option without value prop!");
    });

    return newChildren;
  }, [children, onSelect, disabled, isMobileDevice, classes]);

  /**
   * Dynamically set width of the popover window to
   * the width of the input element
   * */
  useEffect(() => {
    if (!selectInputRef.current) {
      return;
    }

    setInputProperties({
      width: selectInputRef.current.offsetWidth,
    });
  }, [selectInputRef, size.width]);

  /** Manages click on input header component */
  const handleClick = (event: any) => {
    if (disabled) {
      return;
    }

    if (isMobileDevice) {
      setBackdropOpened(true);
    } else if (!disablePopover) {
      /** Opens popover */
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    }

    onClick?.();
  };

  /** Closes popover */
  const handleClose = () => {
    setOpen(false);
  };

  useClickAway([popperContentRef], handleClose);

  const stackProps = merge(
    {
      pl: 2,
      spacing: 2,
      marginTop: "16px",
    },
    optionStackProps,
  );

  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box {...omit(props, "maxWidth")} className={classes.root}>
        <SelectHeader
          ref={selectInputRef}
          maxWidth={props.maxWidth || "100%"}
          placeholder={placeholder}
          className={classes.input}
          open={open || backdropOpened}
          onClick={handleClick}
          disabled={disabled}
        >
          {label}
        </SelectHeader>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          sx={sxPopper}
          placement="bottom"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, -(selectInputRef.current?.clientHeight ?? 0) - 2],
              },
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom"],
              },
            },
          ]}
        >
          <Paper
            ref={popperContentRef}
            sx={{
              display: !open ? "none" : undefined,
              borderRadius: theme.typography.pxToRem(16),
              filter: "drop-shadow(0px 4px 10px rgba(74, 81, 112, 0.05))",
              boxShadow: theme.shadows[5],
              width: inputProperties?.width,
              maxWidth: props.maxWidth || "100%",
              border: `1px solid ${theme.palette.grape.main}`,
              /** To prevent header from jumping */
              marginTop: "-1px",
              overflow: "hidden",
              px: 0,
              py: 0,
            }}
            elevation={0}
            className={classes.popoverContainer}
          >
            <div className={cx(classes.popoverContent)} aria-describedby={id}>
              <SelectHeader
                placeholder={placeholder}
                sx={{ cursor: "pointer" }}
                open={open}
                onClick={handleClose}
                titleProps={{ sx: { opacity: 0.5 } }}
              >
                {popoverLabel}
              </SelectHeader>
              <Stack {...stackProps} overflow="auto">
                {FilteredOptions}
                <Box height={theme.typography.pxToRem(bottomGutterHeight)} />
              </Stack>
            </div>
          </Paper>
        </Popper>
      </Box>

      <Backdrop onRequestClose={() => setBackdropOpened(false)} show={backdropOpened}>
        {({ onClose }) => (
          <>
            <Stack
              mb={theme.typography.pxToRem(28)}
              mx={theme.typography.pxToRem(20)}
              my={theme.typography.pxToRem(20)}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant={"h1"}>{bottomSheerHeader || placeholder}</Typography>

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <Box overflow="auto">
              <Box
                mb={theme.typography.pxToRem(48)}
                mx={theme.typography.pxToRem(20)}
                onClick={onClose}
              >
                {FilteredOptions}
              </Box>
            </Box>
          </>
        )}
      </Backdrop>
    </>
  );
}
