import React from "react";
import { makeStyles } from "../../theme/Theme";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    border: `2px solid ${theme.palette.wall.main}`,
    borderRadius: 16,
    backgroundColor: theme.palette.bloom.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 1,
  },
  text: {
    transform: "translateY(1px)",
    color: theme.palette.const.main,
  },
}));
interface Props {
  num: number;
  x: number;
  y: number;
}
export const CaptchaSelect = ({ num, y, x }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.root} style={{ top: y, left: x }}>
      <Typography variant={"h2"} className={styles.text}>
        {num}
      </Typography>
    </div>
  );
};
