import React from "react";

import { useColor } from "../../hooks/useColor";

export function InfoOutlineIcon({ color, size = 26, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color ?? palette.sparrow.main });

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9997 22.9409C7.50938 22.9409 3.05857 18.4901 3.05857 12.9998C3.05857 7.50941 7.50938 3.05859 12.9997 3.05859C18.4901 3.05859 22.9409 7.50941 22.9409 12.9998C22.9409 18.4901 18.4901 22.9409 12.9997 22.9409Z"
        stroke={iconColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0004 9.17796V13.0015"
        stroke={iconColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0004 15.8688V16.8247"
        stroke={iconColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
