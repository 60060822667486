import React from "react";
import { makeStyles } from "../../theme/Theme";
import { Typography } from "@mui/material";
import { CloseThenIcon } from "../icons/CloseThenIcon";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    color: theme.palette.total.main,
  },
  iconClose: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Props {
  onPress: VoidFunction;
}

export const HeadCaptcha = ({ onPress }: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <Typography
        variant={"text2"}
        className={styles.text}
      >{`Пожалуйста, нажмите на иконки\nв порядке, указанном в подсказке.`}</Typography>
      <div className={styles.iconClose} onClick={onPress}>
        <CloseThenIcon />
      </div>
    </div>
  );
};
