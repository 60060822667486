import { makeStyles, useTheme } from "../../theme/Theme";
import { StatusConfirmCaptcha } from "../../types/app/applicationType";
import React from "react";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 30,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
  },
  text: {
    color: theme.palette.const.main,
  },
}));

export interface BottomTextStatusProps {
  status: StatusConfirmCaptcha;
}

export const BottomTextStatus = ({ status }: BottomTextStatusProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const text = status === "failed" ? "Ошибка, попробуйте еще раз" : "Отлично!";
  const color = status === "failed" ? theme.palette.sparrow.main : theme.palette.eye.main;
  return (
    <div style={{ backgroundColor: color }} className={styles.wrapper}>
      <Typography variant={"text3"} className={styles.text}>
        {text}
      </Typography>
    </div>
  );
};
